

$color-danger: #e10b33;

//$color-primary: #57c448;
$color-secondary: #333;
$color-primary: $color-danger;

$color-white: #fff;
$color-grey-1: #f5f5f5;
$color-grey-2: #d2d2d2;
$color-grey-3: #aaaaaa;
$color-grey-4: #828282;

$color-grey-5: #666; // Used by UIKit.
$color-grey-6: #333; // Used by UIKit.
$color-grey-7: #1e1e1e;
$color-grey-8: #141414;
$color-black: #000;

$color-white-transparent-1: rgba(255, 255, 255, .1);
$color-white-transparent-2: rgba(255, 255, 255, .3);
$color-white-transparent-3: rgba(255, 255, 255, .5);
$color-white-transparent-4: rgba(255, 255, 255, .7);

$color-instagram: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
$color-facebook: #4267B2;
$color-twitter: #1DA1F2;
