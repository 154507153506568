@import 'colors';

body::-webkit-scrollbar {
  width: 1rem !important;
}

body::-webkit-scrollbar-track {
  background: $color-grey-5;
}

body::-webkit-scrollbar-thumb {
  background: $color-danger;
  background: $color-grey-6;
}
