@import 'colors';
@import 'dimensions';

$base-body-font-family: 'Montserrat';
$base-body-font-size: 16px;

* + p {
  margin-top: $dimension-16 !important;
  margin-bottom: $dimension-16 !important;
}

