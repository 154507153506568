@import 'colors';
@import 'dimensions';

$base-heading-font-family: 'Montserrat';
$base-heading-font-weight: 900;

$heading-small-font-size: 1rem;
$heading-small-font-size-m: 1rem;
$heading-small-font-size-l: 1.1rem;

$heading-medium-font-size: 1.2rem;
$heading-medium-font-size-m: 1.3rem;
$heading-medium-font-size-l: 1.4rem;

$heading-large-font-size: 1.5rem;
$heading-large-font-size-m: 1.6rem;
$heading-medium-font-size-l: 1.7rem;

h1, h2, h3, h4, h5, h6 {
  font-weight: 900;
  letter-spacing: .7px;
}

h1 {
  margin-top: $dimension-32;
  margin-bottom: $dimension-16;
  font-weight: 700;
}

h2 {
  font-weight: 900;

  &.uk-heading-small {
    margin-top: $dimension-32;
    margin-bottom: $dimension-8;
  }
}

h3 {
  margin-top: $dimension-16;
  margin-bottom: $dimension-16;
}

* > h1:first-child, /* First h1 child of any element has no margin top */
* > h2:first-child, /* First h2 child of any element has no margin top */
* > h3:first-child /* First h3 child of any element has no margin top */ {
  margin-top: 0 !important;
}
