@import '../base/colors';
@import '../base/dimensions';

//$button-default-background: $color-black;
//$button-default-color: $color-white;

$button-primary-background: $color-primary;
$button-primary-color: $color-white;

$button-secondary-background: $color-secondary;
$button-secondary-color: $color-white;

$button-danger-background: $color-danger;
$button-danger-color: $color-white;

button.uk-button,
a.uk-button {
  border-radius: $dimension-64;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
  border: none !important;

  > svg + span,
  > span + svg,
  > i + span,
  > span + i {
    margin-left: $dimension-16;
  }

  &.uk-button-small > svg + span,
  &.uk-button-small > span + svg {
    margin-left: $dimension-8;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $color-grey-3 !important;
    color: $color-white;
  }
}
