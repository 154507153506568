@import 'colors';

html, body {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
}

$base-body-background: $color-white;

* + p:last-child {
  margin-bottom: 0 !important;
}

img {
  user-select: none !important;
}

// Cuz fa-plus happens to be a bit small and thin in general.
.fa-plus {
  font-weight: 700 !important;
}
