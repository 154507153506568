@import 'colors';
@import 'dimensions';

$form-color: $color-black;
$form-focus-color: $color-black;
$form-focus-border: $color-black;

$form-danger-border: $color-danger;
$form-danger-color: $color-danger;
$text-danger-color: $color-danger;
$text-success-color: $color-primary;

.uk-form {
  textarea, input, select, option {
    min-width: 100%;
    max-width: 100%;
  }

  textarea {
    min-height: 150px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //-webkit-text-fill-color: $color-black;
  //transition: 100ms linear;
}
